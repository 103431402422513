<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('ASIDE.VENDORS') }}
    </p>
    <section class="content-margin">
      <div class="form-group d-flex">
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGE_PRODUCT.SELECT_CATEGORY') }}</label>
          <!-- <div class="icon-input"> -->
            <vue-select 
              :options="supplierCategory.options" 
              class="input cus-select"
              v-model="supplierCategory.selected"
              @input="changeCategory"
            ></vue-select>
            <!-- <img src="/assets/img/icons/search-light.svg" alt=""> -->
          <!-- </div> -->
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGE_PRODUCT.SELECT_PROVIDER') }}</label>
          <vue-select 
            :options="suppliers.options" 
            class="input cus-select"
            v-model="suppliers.selected"
            @input="changeSupplier"
          ></vue-select>
        </div>
        <!-- <div class="form-check-group w-50 form-div d-flex align-items-center px15 m-b-20">
          <div class="d-flex align-items-center form-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="suppliers_data.add_vat"></v-checkbox>
            <label class="px10">{{ $t('MANAGE_SHANIV.DUTY_FREE') }}</label>
          </div> -->
          <!-- <div class="d-flex align-items-center form-checkbox penny-form">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10">{{ $t('MANAGEMENT.EXTRA_CONVERTIBLE_PENNIES') }}</label>
            <input type="text" class="input penny-value">
          </div> -->
        <!-- </div> -->
      </div>
      <div class="form-group d-flex">
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGEMENT.SUPPLIER_NAME') }} <span class="color-red1">*</span></label>
          <input type="text" class="input" v-model="suppliers_data.name" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('TOURISM_SUPPLIER.CONTACT') }}</label>
          <input type="text" class="input" v-model="suppliers_data.contact_name" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('COMMON.PHONE') }}</label>
          <input type="text" class="input" v-model="suppliers_data.contact_phone_number" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('COMMON.ADDRESS') }}</label>
          <input type="text" class="input" v-model="suppliers_data.address" />
        </div>
      </div>
      <div class="form-group d-flex m-b-20">
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGEMENT.SUPPLIER_TYPE') }}</label>
          <input type="text" class="input" v-model="suppliers_data.type" />
          <!-- <vue-select
            :options="test.options"
            class="input cus-select"
            v-model="test.selected"
          ></vue-select> -->
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('DESTINATION.ORDER') }}</label>
          <input type="text" class="input" v-model="suppliers_data.order" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('COMMON.E_MAIL') }}</label>
          <input type="text" class="input" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGEMENT.ANOTHER_PHONE') }}</label>
          <input type="text" class="input" />
        </div>
      </div>
      <div v-if="suppliers_data && Object.keys(suppliers_data).length !== 0 && suppliers_data.constructor === Object"
        class="form-group d-flex upload-color-setting m-b-20" 
      >
        <div class="w-50">
          <div class="w-100 d-flex justify-content-between px15 m-b-20 form-check-group">
            <div class="d-flex align-items-center">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="status"></v-checkbox>
              <label class="px10">{{ $t('COMMON.ACTIVE') }}</label>
            </div>
            <div class="d-flex align-items-center">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="suppliers_data.cancel_option"></v-checkbox>
              <label class="px10">{{ $t('MANAGE_PRODUCT.AUTHORIZED_FOR_REVOCATION') }}</label>
            </div>
            <div class="d-flex align-items-center">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="suppliers_data.default_creation"></v-checkbox>
              <label class="px10">{{ $t('MANAGE_PRODUCT.DEFAULT_IN_SETTING_RESELLER') }}</label>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.FONT_LOGO_SUPPLIER') }}</label>
              <ColorPicker :color="suppliers_data.font_color" v-model="suppliers_data.font_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.PRODUCT_FONT') }}</label>
              <ColorPicker :color="suppliers_data.product_font_color" v-model="suppliers_data.product_font_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.SELECTED_PREFERRED_PRODUCT_FONT') }}</label>
              <ColorPicker :color="suppliers_data.chosen_product_font_color" v-model="suppliers_data.chosen_product_font_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.DOUBT_BACKGROUND') }}</label>
              <ColorPicker :color="suppliers_data.logo_background_color" v-model="suppliers_data.logo_background_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.PRODUCT_BACKGROUND') }}</label>
              <ColorPicker :color="suppliers_data.product_card_background_color" v-model="suppliers_data.product_card_background_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.SELECTED_PREFERRED_PRODUCT_BACKGROUND') }}</label>
              <ColorPicker :color="suppliers_data.chosen_card_background_color" v-model="suppliers_data.chosen_card_background_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.BRIGHT_LOGO_FONT') }}</label>
              <ColorPicker :color="suppliers_data.light_logo_font_color" v-model="suppliers_data.light_logo_font_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.BRIGHT_LOGO_BACKGROUND') }}</label>
              <ColorPicker :color="suppliers_data.light_logo_background_color" v-model="suppliers_data.light_logo_background_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.STAR_COLOR_FAVORITES') }}</label>
              <ColorPicker :color="suppliers_data.favorite_star_color" v-model="suppliers_data.favorite_star_color" />
            </div>
            <div class="w-50 px15 m-b-20">
              <label>{{ $t('MANAGEMENT.SIDE_LOGO_BACKGROUND') }}</label>
              <ColorPicker :color="suppliers_data.product_side_logo_background_color" v-model="suppliers_data.product_side_logo_background_color" />
            </div>
          </div>
        </div>
        <div class="w-50 px15">
          <label>{{ $t('MANAGEMENT.SUPPLIER_LOGO') }} / {{ $t('MANAGE_SHANIV.CATEGORY') }}</label>
          <div class="form-item upload-block p-relative">
            <div class="upload-setting">
              <div
                v-for="(imageToUpload, index) in imagesToUpload"
                :key="imageToUpload.key"
                class="setting-btn bright-btn"
                :class="{'active': selectedImageToUpload === imageToUpload.key}"
                @click="selectedImageToUpload = imageToUpload.key">
                  {{ imageToUpload.title }}
              </div>
            </div>
            <div
              v-for="(imageToUpload, index) in imagesToUpload"
              :key="imageToUpload.key"
              v-show="selectedImageToUpload === imageToUpload.key"
              class="upload-part bdbr-2"
              :style="{ backgroundColor: imageToUpload.background_color_key in suppliers_data && suppliers_data[imageToUpload.background_color_key] }"
            >
              <input
                class="tour-passport__file w-100"
                type="file"
                @change="handleImage"
                accept="image/*"
                :name="imageToUpload.key"
                multiple="multiple"
              />
              <img
                v-show="suppliers_data && suppliers_data[`${imageToUpload.key}_preview`]"
                :src="suppliers_data[`${imageToUpload.key}_preview`]"
                class="preview-img"
              />
              <img
                v-show="!suppliers_data || !suppliers_data[`${imageToUpload.key}_preview`]"
                :src="suppliers_data && suppliers_data[imageToUpload.key] ? suppliers_data[imageToUpload.key] : '/assets/img/icons/upload.png'"
                class="upload-icon"
              />
            </div>
          </div>
          <div class="w-100" v-if="supplierCategory.selected == 'games'">
            <div class="d-flex m-t-10">
              <div class="d-flex align-items-center">
                <v-checkbox color="#0D3856" class="cus-checkbox" v-model="suppliers_data.add_vat"></v-checkbox>
                <label class="px10">{{ $t('MANAGEMENT.ADD_VAT') }}</label>
              </div>
              <div class="w-50 form-div px15 m-b-20">
                <label>{{ $t('MANAGEMENT.PERCENTAGE_PROFIT') }}</label>
                <input type="text" class="input" v-model="suppliers_data.percentage_profit" />
              </div>
            </div>
            <div class="w-100 form-div m-b-20">
              <label>{{ $t('MANAGEMENT.PRODUCTS_SOURCE') }}</label>
              <input type="text" class="input" v-model="suppliers_data.products_source" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group d-flex">
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGEMENT.MAX_MARKET_PROFIT_COMMISSION') }}</label>
          <input type="text" class="input" v-model="suppliers_data.max_percentage_profit" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGEMENT.MIN_ADDITIONAL_COMMISSION_CONSUMER') }}</label>
          <input type="text" class="input" v-model="suppliers_data.min_percentage_profit" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <div class="d-flex align-items-center label-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10">{{ $t('MANAGEMENT.MAX_AMOUNT_ALLOWED_PAYMENT') }}</label>
          </div>
          <input type="text" class="input" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <div class="d-flex align-items-center label-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="suppliers_data.max_payment"></v-checkbox>
            <label class="px10">{{ $t('MANAGEMENT.LIMIT_AUTO_PAYMENT_CREDIT') }}</label>
          </div>
          <input type="text" class="input" />
        </div>
      </div>
      <div class="form-group d-flex">
        <div class="w-25 form-div px15 m-b-20">
          <div class="d-flex align-items-center label-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10">{{ $t('MANAGEMENT.CONSUMER_COMMISSION_STAIRS') }}</label>
          </div>
          <input type="text" class="input" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <div class="d-flex align-items-center label-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10">{{ $t('MANAGEMENT.CREDIT_CARD_PROVIDE') }}</label>
          </div>
          <input type="text" class="input" v-model="suppliers_data.credit_card_id" />
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGEMENT.HOW_TO_CANCEL') }}</label>
          <vue-select
            :options="test.options"
            class="input cus-select"
            v-model="test.selected"
          ></vue-select>
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <div class="d-flex align-items-center label-checkbox">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10">{{ $t('MANAGEMENT.EMAIL_TO_CANCEL') }}</label>
          </div>
          <input type="text" class="input" />
        </div>
      </div>
      <div class="px15">
        <div class="d-flex align-items-center m-b-10">
          <v-checkbox color="#0D3856" class="cus-checkbox" v-model="showAdditionalDetails"></v-checkbox>
          <label class="px10">{{ $t('MANAGEMENT.HELP') }}</label>
        </div>
        <vue-editor class="bg-white" v-model="suppliers_data.help" v-show="showAdditionalDetails"></vue-editor>
      </div>
      <div class="d-flex flex-wrap align-items-center bottom-action m-t-30 m-b-40">
        <button 
          class="primary-btn btn-limit-width"
          @click="update()"
        >
          {{ $t("COMMON.SAVE") }}
        </button>
        <button 
          class="primary-btn btn-limit-width bg-yellow"
        >
          {{ $t("COMMON.NEW") }}
        </button>
        <button v-if="showSyncButton"
          class="primary-btn btn-limit-width"
          @click="syncSupplierProductsHandler"
        >
          {{ $t("MANAGEMENT.SYNC_NOW") }}
        </button>
      </div>
    </section>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>    
</template>

<script>
import { mapState, mapActions } from "vuex";
import ColorPicker from '../../../components/ColorPicker'
export default {
  name: 'CPProviders',
  components: {
    ColorPicker
  },
  data() {
    return {
      test: {
        options: ['test'],
        selected: 'test',
      },
      supplierCategory: {
        options: [],
        selected: ''
      },
      suppliers: {
        options: [],
        selected: ''
      },
      status: false,
      showAdditionalDetails: false,
      testValue: "",

      imagesToUpload: [
        {
          title: this.$t('MANAGEMENT.DARK'),
          key: 'image',
          background_color_key: 'logo_background_color',
        },
        {
          title: this.$t('MANAGEMENT.BRIGHT'),
          key: 'light_logo',
          background_color_key: 'light_logo_background_color',
        },
      ],
      selectedImageToUpload: 'image',
      suppliers_data: {}
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.supplier.loading,
      supplier_items: state => state.supplier.supplier_items,
      supplierData: state => state.supplier.supplierData,
    }),
    suppliersOptionsList() {
      let suppliersOptionsList = [];

      if (this.supplier_items) {
          for (let supplierType in this.supplier_items) {
              let supplierTypeItems = this.supplier_items[supplierType];
              for (let supplerData of supplierTypeItems) {
                  suppliersOptionsList.push({id: supplerData.id, name: supplerData.name});
              }
          }
      }

      suppliersOptionsList.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      suppliersOptionsList.unshift({id: null, name: 'All'});

      return suppliersOptionsList;
    },
    storageSupplierCategoryId() {
      return localStorage.getItem("cpSupplierCategory");
    },
    storageSuppliersId() {
      return localStorage.getItem("cpSuppliersId");
    },
    showSyncButton() {
      return this.suppliers_data && this.suppliers_data.id
          ? [1100,1101].includes(this.suppliers_data.id) : false;
    },
  },
  async created () {
    await this.getAllSupplierItems()
    if (this.supplier_items) {
      for (let supplierType in this.supplier_items) {
        this.supplierCategory.options.push(supplierType);
      }
      if (this.storageSupplierCategoryId != "undefined") {
        this.supplierCategory.selected = this.storageSupplierCategoryId;
      } else {
        this.supplierCategory.selected = this.supplierCategory.options[0];
      }
      this.changeCategory();
    }
  },
  methods: {
    ...mapActions('supplier', {
      getAllSupplierItems: 'getAll',
      getSupplierDataById: 'getSupplierDataById',
      updateSupplierDataById: 'updateSupplierDataById',
      syncSupplierProducts: 'syncSupplierProducts',
    }),
    syncSupplierProductsHandler() {
      if (!this.suppliers_data || !this.suppliers_data.id) {
        return false;
      }

      this.syncSupplierProducts({supplier_id: this.suppliers_data.id});
    },
    handleImage(e) {
      if (e.target.files.length > 0) {
        const imageKey = e.target.name;
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (e) => {
          this.suppliers_data[`${imageKey}_file`] = imageFile;
          this.suppliers_data[`${imageKey}_preview`] = e.target.result;
          this.suppliers_data = {...this.suppliers_data};
        };
      }
    },
    changeCategory() {
      this.suppliers = {
        options: [],
        selected: ''
      };
      const supplierType = this.supplierCategory.selected;
      const supplierTypeItems = this.supplier_items[supplierType];
      for (let supplerData of supplierTypeItems) {
        this.suppliers.options.push({
          code: supplerData.id, 
          label: supplerData.name
        });
      };
      if (this.storageSuppliersId != "undefined") {
        const supplierTemp = this.suppliers.options.find((el) => {
          return el.code == this.storageSuppliersId;
        });
          if (supplierTemp) {
            this.suppliers.selected = supplierTemp;
          } else {
            this.suppliers.selected = this.suppliers.options[0];
          }
      } else {
        this.suppliers.selected = this.suppliers.options[0];
      }
      this.changeSupplier();
    },
    changeSupplier() {
      const currentSupplierId = this.suppliers.selected.code;
      this.suppliers_data = {};
      localStorage.setItem("cpSupplierCategory", this.supplierCategory.selected);
      localStorage.setItem("cpSuppliersId", currentSupplierId);
      
      this.getSupplierDataById({
        supplier_id: currentSupplierId
      }).then(() => {
        this.suppliers_data = {...this.supplierData};
        this.setStatus();
      });
    },
    update() {
      const currentSupplierId = this.suppliers.selected.code;
      let formData = new FormData();

      formData.append('name', this.suppliers_data.name);
      formData.append('type', this.suppliers_data.type);
      formData.append('credit_card_id', this.suppliers_data.credit_card_id || '');
      formData.append('order', this.suppliers_data.order || '');
      formData.append('status', this.status);
      formData.append('max_percentage_profit', this.suppliers_data.max_percentage_profit || '');
      formData.append('min_percentage_profit', this.suppliers_data.min_percentage_profit || '');
      formData.append('max_payment', this.suppliers_data.max_payment || '');
      formData.append('credit_card_limit', this.suppliers_data.credit_card_limit || '');
      formData.append('products_source', this.suppliers_data.products_source);
      formData.append('add_vat', this.suppliers_data.add_vat);
      formData.append('percentage_profit', this.suppliers_data.percentage_profit || '');
      formData.append('default_creation', this.suppliers_data.default_creation);
      formData.append('cancel_option', this.suppliers_data.cancel_option);
      formData.append('show_additional_details', this.suppliers_data.show_additional_details);
      formData.append('additional_details_default_language', this.suppliers_data.additional_details_default_language);
      formData.append('additional_details_he', this.suppliers_data.additional_details_he || '');
      formData.append('additional_details_ar', this.suppliers_data.additional_details_ar || '');
      formData.append('additional_details_en', this.suppliers_data.additional_details_en || '');
      formData.append('contact_phone_number', this.suppliers_data.contact_phone_number || '');
      formData.append('contact_name', this.suppliers_data.contact_name || '');
      formData.append('product_card_background_color', this.suppliers_data.product_card_background_color || '');
      formData.append('logo_background_color', this.suppliers_data.logo_background_color || '');
      formData.append('font_color', this.suppliers_data.font_color || '');
      formData.append('chosen_card_background_color', this.suppliers_data.chosen_card_background_color || '');
      formData.append('light_logo_background_color', this.suppliers_data.light_logo_background_color || '');
      formData.append('favorite_star_color', this.suppliers_data.favorite_star_color || '');
      formData.append('product_font_color', this.suppliers_data.product_font_color || '');
      formData.append('chosen_product_font_color', this.suppliers_data.chosen_product_font_color || '');
      formData.append('light_logo_font_color', this.suppliers_data.light_logo_font_color || '');
      formData.append('product_side_logo_background_color', this.suppliers_data.product_side_logo_background_color || '');

      for (let imageToUpload of this.imagesToUpload) {
        if (`${imageToUpload.key}_file` in this.suppliers_data
          && this.suppliers_data[`${imageToUpload.key}_file`]) {
          formData.append(imageToUpload.key, this.suppliers_data[`${imageToUpload.key}_file`]);
        }
      }

      this.updateSupplierDataById({
        supplier_id: currentSupplierId,
        supplier_data: formData
      }).then(() => {
        this.changeSupplier();
      });
    },
    setStatus() {
      this.status = this.suppliers_data && (this.suppliers_data.status === 1 || this.suppliers_data.status === '1');
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  white-space: nowrap;
}
.form-checkbox {
  margin-top: 25px;
}
.penny-value {
  width: 70px;
}
.penny-form {
  margin-right: 30px;
}
.label-checkbox {
  height: 27px;
}
.upload-block {
  .upload-setting {
    position: absolute;
    top: -18px;
    left: 0;
    display: flex;
    cursor: pointer;
    .setting-btn {
      height: 16px;
      font-size: 12px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      color: #002036;
      &.active {
        background-color: #002036;
        color: #fff;
      }
      &.bright-btn {
        margin: 0 5px;
      }
    }
  }
  .upload-part {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    .upload-icon {
      height: 70%;
      max-width: 80%;
    }
    .preview-img {
      object-fit: contain;
      height: 70%;
      max-width: 80%;
    }
  }
  .tour-passport__file {
    height: 300px;
  }
}

.bottom-action {
  .primary-btn {
    margin: 0 15px 15px;
  }
}

@media screen and (max-width: 1250px) {
  .form-group {
    flex-wrap: wrap;
  }
  .w-25 {
    width: 50% !important;
  }
}
@media screen and (max-width: 1080px) {
  .upload-color-setting {
    flex-wrap: wrap;
    .w-50 {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 650px) {
  .w-50,
  .w-25 {
    width: 100% !important;
  }
  .bottom-action {
    .primary-btn {
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
  .form-check-group {
    flex-wrap: wrap;
    .penny-form {
      margin-right: 0;
    }
  }
}

.ltr-type {
  .upload-block {
    .upload-setting {
      left: unset;
      right: 0;
    }
  }
}
</style>